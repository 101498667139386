@tailwind base;
@tailwind components;
@tailwind utilities;

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* Ensure it's above other elements */
}

.overlay-content {
  /* Style your overlay content here */
  z-index: 1001;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.comment-enter {
  opacity: 0;
  /* Start with invisible comments */
  animation: fadeIn 0.5s ease-out forwards;
}